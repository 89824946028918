<template>
  <v-app-bar
    v-scroll="onScroll"
    app
    elevate-on-scroll
    :color="!isScrolling ? 'transparent' : 'grey lighten-3'"
  >
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-img
        class="shrink"
        max-width="14%"
        src="/static/alpha-creative-logo.png"
        style="cursor: pointer"
        @click="$router.push('/')"
      />
      <v-spacer />
      <v-menu
        offset-y
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="text-capitalize subtitle-1 mx-1"
            v-bind="attrs"
            text
            v-on="on"
          >
            Σύνδεσμοι<v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list
          color="primary"
        >
          <v-list-item
            v-for="(list_item, i) in list_items"
            :key="i"
            :to="list_item.to"
            link
            color="primary"
          >
            <v-list-item-title
              class="black--text"
            >
              {{ list_item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        offset-y
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="text-capitalize subtitle-1 mx-1"
            v-bind="attrs"
            text
            v-on="on"
          >
            Άδειες<v-icon> mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list
          color="primary"
        >
          <v-list-item
            v-for="(list_item2, i) in list_items2"
            :key="i"
            :to="list_item2.to"
            link
            color="primary"
          >
            <v-list-item-title
              class="black--text"
            >
              {{ list_item2.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-for="(item, i) in items"
        :key="i"
        :to="item.to"
        class="text-capitalize subtitle-1 mx-1"
        text
      >
        <span v-text="item.text" />
      </v-btn>
    </template>

    <v-menu v-else>
      <template v-slot:activator="{ }">
        <v-img
          max-width="40%"
          src="/static/alpha-creative-logo.png"
          @click="$router.push('/')"
        />
        <v-spacer />
        <v-menu
          offset-y
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="text-capitalize subtitle-1 mx-0"
              v-bind="attrs"
              text
              width="82"
              v-on="on"
            >
              Σύνδεσμοι
            </v-btn>
          </template>
          <v-list
            color="primary"
          >
            <v-list-item
              v-for="(list_item, i) in list_items"
              :key="i"
              :to="list_item.to"
              link
              color="primary"
            >
              <v-list-item-title
                class="black--text"
              >
                {{ list_item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer />
        <v-menu
          offset-y
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="text-capitalize subtitle-1 mx-0"
              v-bind="attrs"
              text
              width="50"
              v-on="on"
            >
              Άδειες
            </v-btn>
          </template>
          <v-list
            color="primary"
          >
            <v-list-item
              v-for="(list_item2, i) in list_items2"
              :key="i"
              :to="list_item2.to"
              link
              color="primary"
            >
              <v-list-item-title
                class="black--text"
              >
                {{ list_item2.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer />
        <v-menu
          offset-y
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="text-capitalize subtitle-1 mx-0"
              v-bind="attrs"
              text
              width="56"
              v-on="on"
            >
              Μενού
            </v-btn>
          </template>
          <v-list
            color="primary"
          >
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              :to="item.to"
              link
              color="primary"
            >
              <v-list-item-title
                class="black--text"
              >
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer />
      </template>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Utilities
  import { mapMutations } from 'vuex'

  export default {
    data: () => ({
      isScrolling: false,
      items: [
        {
          to: '/about-me',
          text: 'Σχετικά με εμάς'
        },
        {
          to: '/my-work',
          text: 'Υπηρεσίες'
        },
        {
          to: '/contact-me',
          text: 'Επικοινωνήστε μαζί μας'
        }
      ],
      list_items: [
        {
          to: '/sideServices/prosorino_diploma',
          title: 'Χορήγηση Προσωρινού Διπλώματος'
        },
        {
          to: '/sideServices/testdriveme',
          title: 'Online Test'
        },
        {
          to: '/vivlia',
          title: 'Βιβλία Υπουργείου Μεταφορών'
        },
        {
          to: '/sideServices/xorigisi_paravolou',
          title: 'Χορήγηση Παραβόλου'
        },
        {
          to: '/sideServices/simvevlimenoi_iatroi',
          title: 'Συμβεβλημένοι Ιατροί'
        }
      ],
      list_items2: [
        {
          to: '/AutoDiploma',
          title: 'Αυτοκίνητο'
        },
        {
          to: '/fortigoDiploma',
          title: 'Φορτηγό'
        },
        {
          to: '/leofDiploma',
          title: 'Λεωφορείο'
        },
        {
          to: '/ntalikaDiploma',
          title: 'Ρυμουλκούμενο'
        },
        {
          to: '/taxiDiploma',
          title: 'Ταξί'
        },
        {
          to: '/epagelmatikoDiploma',
          title: 'Π.Ε.Ι'
        },
        {
          to: '/amDiploma',
          title: 'Μοτοσυκλέτα ΑΜ'
        },
        {
          to: '/a1Diploma',
          title: 'Μοτοσυκλέτα Α1'
        },
        {
          to: '/a2Diploma',
          title: 'Μοτοσυκλέτα Α2'
        },
        {
          to: '/aDiploma',
          title: 'Μοτοσυκλέτα Α'
        }
      ]
    }),

    methods: {
      ...mapMutations(['toggleDrawer']),
      onScroll () {
        this.isScrolling = (window.pageYOffset ||
          document.documentElement.scrollTop || 0) > 20
      }
    }
  }
</script>

<style scoped>

</style>
