var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],attrs:{"app":"","elevate-on-scroll":"","color":!_vm.isScrolling ? 'transparent' : 'grey lighten-3'}},[(_vm.$vuetify.breakpoint.mdAndUp)?[_c('v-img',{staticClass:"shrink",staticStyle:{"cursor":"pointer"},attrs:{"max-width":"14%","src":"/static/alpha-creative-logo.png"},on:{"click":function($event){return _vm.$router.push('/')}}}),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize subtitle-1 mx-1",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Σύνδεσμοι"),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,false,1819925093)},[_c('v-list',{attrs:{"color":"primary"}},_vm._l((_vm.list_items),function(list_item,i){return _c('v-list-item',{key:i,attrs:{"to":list_item.to,"link":"","color":"primary"}},[_c('v-list-item-title',{staticClass:"black--text"},[_vm._v(" "+_vm._s(list_item.title)+" ")])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize subtitle-1 mx-1",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Άδειες"),_c('v-icon',[_vm._v(" mdi-menu-down")])],1)]}}],null,false,2679010887)},[_c('v-list',{attrs:{"color":"primary"}},_vm._l((_vm.list_items2),function(list_item2,i){return _c('v-list-item',{key:i,attrs:{"to":list_item2.to,"link":"","color":"primary"}},[_c('v-list-item-title',{staticClass:"black--text"},[_vm._v(" "+_vm._s(list_item2.title)+" ")])],1)}),1)],1),_vm._l((_vm.items),function(item,i){return _c('v-btn',{key:i,staticClass:"text-capitalize subtitle-1 mx-1",attrs:{"to":item.to,"text":""}},[_c('span',{domProps:{"textContent":_vm._s(item.text)}})])})]:_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-img',{attrs:{"max-width":"40%","src":"/static/alpha-creative-logo.png"},on:{"click":function($event){return _vm.$router.push('/')}}}),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize subtitle-1 mx-0",attrs:{"text":"","width":"82"}},'v-btn',attrs,false),on),[_vm._v(" Σύνδεσμοι ")])]}}],null,true)},[_c('v-list',{attrs:{"color":"primary"}},_vm._l((_vm.list_items),function(list_item,i){return _c('v-list-item',{key:i,attrs:{"to":list_item.to,"link":"","color":"primary"}},[_c('v-list-item-title',{staticClass:"black--text"},[_vm._v(" "+_vm._s(list_item.title)+" ")])],1)}),1)],1),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize subtitle-1 mx-0",attrs:{"text":"","width":"50"}},'v-btn',attrs,false),on),[_vm._v(" Άδειες ")])]}}],null,true)},[_c('v-list',{attrs:{"color":"primary"}},_vm._l((_vm.list_items2),function(list_item2,i){return _c('v-list-item',{key:i,attrs:{"to":list_item2.to,"link":"","color":"primary"}},[_c('v-list-item-title',{staticClass:"black--text"},[_vm._v(" "+_vm._s(list_item2.title)+" ")])],1)}),1)],1),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize subtitle-1 mx-0",attrs:{"text":"","width":"56"}},'v-btn',attrs,false),on),[_vm._v(" Μενού ")])]}}],null,true)},[_c('v-list',{attrs:{"color":"primary"}},_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.to,"link":"","color":"primary"}},[_c('v-list-item-title',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),1)],1),_c('v-spacer')]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }